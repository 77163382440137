import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const PRFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const {
    goToPrivacy,
    goToTerms,
    goToContact,
    goToLanding,
    goToFAQ,
    goToReviews,
  } = useRouter();
  const { isTablet } = useQuery();
  //
  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT>Terms & Conditions</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT>Privacy Policy</StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              {disclaimerText.map((paragraph, index) => (
                <StyledText key={index}>{paragraph}</StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default PRFooter;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  padding-top: 1.25rem;
  @media ${tablet} {
    align-items: center;
  }
`;

const StyledTextT = styled(Text)`
  color: #434444;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 84rem;
  margin: auto;
  gap: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const StyledText = styled(Text)`
  color: #727373;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid #f1eeed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
    border-top: none;
  }
`;

const Container = styled.div<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 3rem 2rem;
  gap: 1.5rem;
  background: #f4f4f4;
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 2rem 1rem 2.5rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2.25rem;
  @media ${tablet} {
    height: unset;
    gap: 1.25rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;
