import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  mMobile,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile_landing.png';
import socProof_des from 'assets/images/landing/desktop_landing.png';
import Logo from 'assets/icons/logo.svg';
// import Triangle from "assets/icons/triangle.svg";
// import WhitePercent from "assets/icons/white-percent.svg";
import { usePageView, useScrollToTop } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { useLocation } from '@reach/router';
import { normalizeStates } from 'utils/localization';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
  hasBanner?: boolean;
}

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const [active, setActive] = useState<string | null>(null);
  const [animateButton, setAnimateButton] = useState<string | null>(null);
  const { isTablet } = useQuery();
  const location = useLocation();

  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;

  const geo = useSelector((state: AppState) => state.user?.geolocation);

  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;

  usePageView({
    city: encodeURIComponent(
      geo?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geo?.iso_country?.toLowerCase(),
    state: normalizeStates(geo?.iso_country || '', geo?.iso_state || ''),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.href, location.pathname]);

  const pickSocialProfImage = (variant?: string) =>
    // if (variant === "blackFriday") {
    //   return {
    //     mob: socProofBF,
    //     des: socProof_desBF,
    //   };
    // }
    // if (variant === 'xmas') {
    //   return {
    //     mob: xmasSocProof,
    //     des: xmasSocProof_des,
    //   };
    // }
    // if (variant === 'ny') {
    //   return {
    //     mob: nySocProof,
    //     des: nySocProof_des,
    //   };
    // }
    ({
      mob: socProof,
      des: socProof_des,
    });

  const handleMouseDown = (buttonValue: string) => {
    setAnimateButton(buttonValue); // Animate the specific button
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimateButton(null); // Reset the animation after a brief delay
    }, 100);
  };

  const handleOptionClick = (value: string, label: string) => {
    setActive(value);
    setTimeout(
      () =>
        onOptionSelect({
          [question?.key]: value,
          label: label,
        }),
      0,
    );
  };

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <>
      <Header isMobileApp={isAddsFlow} isSticky={!question?.bannerTexts} />
      <Outer isFullHeight={isAddsFlow}>
        <ContentContainer>
          <DynamicImage src={question.image} alt="" />
          <InnerContainer>
            <Title dangerouslySetInnerHTML={{ __html: question?.label }} />
            <Subtitle
              dangerouslySetInnerHTML={{ __html: question?.optionsLabel }}
            />
            <ButtonsContainer>
              {question?.options?.map((option, index) => (
                <Button
                  onMouseDown={() => handleMouseDown(option.value)}
                  onMouseUp={handleMouseUp}
                  animate={animateButton === option.value} // Only animate the active button
                  inverted={option?.inverted}
                  key={index}
                  onClick={() =>
                    handleOptionClick(option?.value, option?.label)
                  }
                  active={option.value === active}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonsContainer>
          </InnerContainer>
        </ContentContainer>
      </Outer>
      {isAddsFlow ? (
        <PRFooter disclaimerText={question?.disclaimerParagraphsPR} />
      ) : (
        <LCFooter disclaimerText={question?.disclaimerParagraphs} />
      )}
    </>
  );
};

export default Landing;

const Button = styled(PrimaryButton)<{
  inverted?: boolean;
  active?: boolean;
  animate: boolean;
}>`
  ${({ inverted }) => inverted && 'background: #FFD817; p{color: #141515;}'}
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem 2.25rem;
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 225px);
  max-width: 34.5rem;
  width: 100%;
  margin: 0 auto;

  @media ${tablet} {
  }
`;

const ButtonBannerText = styled.p`
  color: #fff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;
  b {
    font-weight: 700;
  }
  span {
    font-weight: 700;
    color: #f7c800;
  }
  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.024rem;
  }
`;

const GenderButtonWithBanner = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;

  @media ${tablet} {
    height: 3rem;
  }
`;

const Outer = styled.section<{ isFullHeight: boolean }>`
  background: #fff;
  ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4.5rem);'}
  @media ${tablet} {
    ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4rem);'}
  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const ButtonsContainerWithBanner = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 1rem;
  @media ${tablet} {
    ${({ hasBanner }) => !hasBanner && 'padding-bottom: 32px;'}
    justify-content: center;
  }
`;

const Title = styled.h1`
  color: #141515;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  max-width: 35rem;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 2rem 0 1rem;
  span {
    font-weight: 700;
  }
`;

const GenderButton = styled(PrimaryButton)<{
  color?: boolean;
  animate: boolean;
  isBlack?: boolean;
}>`
  padding: 0 1rem;
  max-width: 10.3125rem;
  width: 100%;
  height: 3rem;
  ${({ color }) => color && `background:${color};`}
  ${({ isBlack }) => isBlack && `p{color: #141515;}`}
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
  @media ${tablet} {
  }
`;

const OtherGenderButton = styled(GenderButton)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  background-color: transparent;

  border: none;
  div p {
    text-align: center;
    color: #000;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
  }
`;
