import React, { ComponentPropsWithoutRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile, tablet } from 'styles/breakpoints';
import RightArrow from 'assets/icons/quiz/right-arrow.svg';
import Checked from 'assets/icons/quiz/checked-checkbox.svg';
import Empty from 'assets/icons/quiz/empty-checkbox.svg';
import Single from 'assets/icons/quiz-arrow.svg';
import quizSvgs from 'utils/constants/quizSvgs';
import { DynamicImage } from './images/DynamicImage';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  isMultiple?: boolean;
  emoji?: string;
  subtitle?: string;

  hasDivider?: boolean;
  smallEmoji?: boolean;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  animate: boolean;
  hasSubtitle?: boolean;
  hasDivider?: boolean;
}>`
  background: ${({ active }) => (active ? '#E6F6EA' : '#F7F7F7')};
  border: 1px solid
    ${({ active }) => (active ? '#03A62C' : 'rgba(0, 0, 0, 0.08)')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ hasSubtitle }) => (hasSubtitle ? '4.9375rem' : '4rem')};
  height: ${({ hasSubtitle }) => (hasSubtitle ? '4.9375rem' : '4rem')};

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};
  border-radius: 0.625rem;
  padding: 1rem 0.75rem;
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
  ${({ hasDivider }) => hasDivider && 'margin-bottom: 0.75rem;'}
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
  touch-action: manipulation;
  @media ${tablet} {
    transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
    ${({ hasDivider }) => hasDivider && 'margin-bottom: 0.5rem;'}
  }
`;

const ButtonText = styled(Text)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 1rem;
`;

const Inner = styled.section`
  display: flex;
  gap: 0.75rem;
`;
const CheckedIcon = styled(Checked)<{ active: boolean }>`
  transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0.7)')};
  transition: transform 0.3s ease-out;
  path {
    stroke: ${({ active }) => (active ? '#fff' : '#fff')};
  }
`;

const CheckedSvg = styled.section<{ active: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  background: ${({ active }) => (active ? '#03A62C' : '#F7F7F7')};
  border: 1.2px solid ${({ active }) => (active ? '#03A62C' : '#141515')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  isMultiple,
  emoji,
  subtitle,
  hasDivider,
  smallEmoji,
  ...props
}) => {
  const [animate, setAnimate] = useState(false);

  const handleMouseDown = () => {
    setAnimate(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimate(false);
    }, 100);
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <>
      <Container
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
        active={active}
        maxSelected={maxSelected}
        as="button"
        animate={animate}
        {...props}
        hasSubtitle={!!subtitle}
      >
        <Inner>
          {isMultiple && (
            <CheckedSvg active={active as boolean}>
              <CheckedIcon active={active as boolean} />
            </CheckedSvg>
          )}
          <TextContainer>
            <ButtonText>{children}</ButtonText>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TextContainer>
        </Inner>
        {!isMultiple && !emoji && <RightArrow />}
        {emoji && (
          <EmojiContainer isSmall={smallEmoji}>
            <Emoji src={emoji} alt="emoji" isSmall={smallEmoji} />
          </EmojiContainer>
        )}
      </Container>
      {hasDivider && <Divider />}
    </>
  );
};

export default React.memo(QuizAnswerButton);

const Divider = styled.section`
  width: 100%;
  height: 0.0625rem;
  background-color: #1415151a;
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
`;

const Subtitle = styled.p`
  color: #727373;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
`;

const EmojiContainer = styled.section<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
  height: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
`;

const Emoji = styled(DynamicImage)<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
  height: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
`;
